import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/example.png";
import { Male } from "./assets/constants/index.js";
export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 8px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 8px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 8px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  height: 400px;
  @media (min-width: 767px) {
    height: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledItems = styled.div`
  flex: 1;
  @media (min-width: 500px) {
    /* background-color: red; */
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

const layer = (item, name) => {
  console.log(item.elements);
  return (
    <div style={{ marginBottom: 24 }}>
      <div>
        <s.TextTitle style={{ textAlign: "left" }}>{name}</s.TextTitle>
      </div>

      <div style={{ display: "flex", flex: 1, alignSelf: "flex-start", flexDirection: "row", flexWrap: "wrap" }}>
        {item.elements.map((element, index) => {
          console.log(element);
          let nextWeight = item.elements[index + 1]?.weight || 0;
          return (
            <div style={{ flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  backgroundColor: "rgba(255,255,255,0.5)",
                  flexDirection: "column",
                  borderRadius: 8,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 50,
                  height: 50,
                  marginTop: 6,
                  marginRight: 6,
                  marginBottom: 3,
                }}
              >
                <img style={{ width: 35 }} src={element.image} />
              </div>
              <div>
                <s.TextDescriptionSmall>{element.weight - nextWeight}%</s.TextDescriptionSmall>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [claimingNft, setClaimingNft] = useState(false);

  console.log(Male.accessory.elements);
  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your CryptoStory NFT, please wait...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: "285000",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((10 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback("WOW, you now own a CryptoStory NFT. go visit Opensea.io to view it.");
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "#27276E" }}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24, maxWidth: 1000, alignSelf: "center" }}>
        <s.TextTitle style={{ textAlign: "center", fontSize: 24, fontWeight: "bold", marginBottom: 12 }}>Mint a CryptoStory NFT</s.TextTitle>
        <s.SpacerMedium />
        <div style={{ marginBottom: 24 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={i1} />
            <s.SpacerMedium />
            <s.TextTitle style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}>{data.totalSupply}/10000</s.TextTitle>
          </s.Container>
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ backgroundColor: "#080836", padding: 24, borderRadius: 12 }}>
            {Number(data.totalSupply) >= 10000 ? (
              <>
                <p style={{ color: "white", fontSize: 14, textAlign: "center", lineHeight: 2, marginBottom: 12 }}>The sale has ended.</p>
                <p style={{ color: "white", fontSize: 10, textAlign: "center", lineHeight: 2, marginBottom: 12 }}>
                  You can still find CryptoStory NFTs on{" "}
                  <a style={{ textDecoration: "none" }} target={"_blank"} href={"https://opensea.io/collection/cryptostory-club"}>
                    opensea.io
                  </a>
                </p>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <p style={{ color: "white", fontSize: 10, textAlign: "center", lineHeight: 1, marginBottom: 12 }}>Please connect to the</p>
                    <p style={{ color: "white", fontSize: 18, textAlign: "center", lineHeight: 1, marginBottom: 18 }}>Polygon Network</p>

                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      PRESS HERE
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>{blockchain.errorMsg}</s.TextDescription>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <p style={{ color: "white", fontSize: 14, textAlign: "center", lineHeight: 1.5, marginBottom: 12 }}>1 CTOS costs 10 MATIC</p>
                    <p style={{ color: "white", fontSize: 10, textAlign: "center", lineHeight: 1.5, marginBottom: 24 }}>{feedback}</p>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs(1);
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY 1 CTOS"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <s.SpacerSmall />
        <div style={{ minWidth: "75%", marginBottom: 24 }}>
          <s.TextDescription style={{ textAlign: "left", fontSize: 10, lineHeight: 2 }}>
            Please make sure you are connected to the right network (Polygon Mainnet) and the correct address. Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
        </div>

        <StyledItems>
          {layer(Male.face, "Faces")}
          {layer(Male.hair, "Hairs")}

          {layer(Male.pants, "Pants")}
          {layer(Male.coat, "Tops")}

          {layer(Male.shoe, "Shoes")}
          {layer(Male.cape, "Capes")}

          {layer(Male.accessory, "Eyes")}
          {layer(Male.gloves, "Hands")}
        </StyledItems>
      </s.Container>
    </s.Screen>
  );
}

export default App;
