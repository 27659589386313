import face_00025820 from "../images/face/00025820.img/default.face.png";
import face_00025825 from "../images/face/00025825.img/default.face.png";
import face_00026017 from "../images/face/00026017.img/default.face.png";
import face_00025221 from "../images/face/00025221.img/default.face.png";
import face_00026049 from "../images/face/00026049.img/default.face.png";
import face_00020840 from "../images/face/00020840.img/default.face.png";
import face_00020846 from "../images/face/00020846.img/default.face.png";
import face_00020842 from "../images/face/00020842.img/default.face.png";
import face_00020868 from "../images/face/00020868.img/default.face.png";
import face_00020865 from "../images/face/00020865.img/default.face.png";
import face_00023211 from "../images/face/00023211.img/default.face.png";
import face_00025222 from "../images/face/00025222.img/default.face.png";
import face_00025217 from "../images/face/00025217.img/default.face.png";
import face_00025241 from "../images/face/00025241.img/default.face.png";
import face_00025243 from "../images/face/00025243.img/default.face.png";
import face_00025372 from "../images/face/00025372.img/default.face.png";
import face_00025772 from "../images/face/00025772.img/default.face.png";
import face_00027613 from "../images/face/00027613.img/default.face.png";
import face_00020072 from "../images/face/00020072.img/default.face.png";
import face_00020071 from "../images/face/00020071.img/default.face.png";
import face_00025011 from "../images/face/00025011.img/default.face.png";
import face_00021250 from "../images/face/00021250.img/default.face.png";
import face_00050652 from "../images/face/00050652.img/default.face.png";
import face_00025004 from "../images/face/00025004.img/default.face.png";

import hair_00030020 from "../images/hair/00030020.img/default.hairOverHead.png";
import hair_00046717 from "../images/hair/00046717.img/default.hairOverHead.png";
import hair_00046080 from "../images/hair/00046080.img/default.hairOverHead.png";
import hair_00046897 from "../images/hair/00046897.img/default.hairOverHead.png";
import hair_00045305 from "../images/hair/00045305.img/default.hairOverHead.png";
import hair_00033371 from "../images/hair/00033371.img/default.hairOverHead.png";
import hair_00043273 from "../images/hair/00043273.img/default.hairOverHead.png";
import hair_00036790 from "../images/hair/00036790.img/default.hairOverHead.png";
import hair_00043180 from "../images/hair/00043180.img/default.hairOverHead.png";
import hair_00046850 from "../images/hair/00046850.img/default.hairOverHead.png";
import hair_00043660 from "../images/hair/00043660.img/default.hairOverHead.png";
import hair_00046734 from "../images/hair/00046734.img/default.hairOverHead.png";
import hair_00046881 from "../images/hair/00046881.img/default.hairOverHead.png";
import hair_00046810 from "../images/hair/00046810.img/default.hairOverHead.png";
import hair_00046685 from "../images/hair/00046685.img/default.hairOverHead.png";
import hair_00046910 from "../images/hair/00046910.img/default.hairOverHead.png";
import hair_00044101 from "../images/hair/00044101.img/default.hairOverHead.png";
import hair_00040590 from "../images/hair/00040590.img/default.hairOverHead.png";
import hair_00046741 from "../images/hair/00046741.img/default.hairOverHead.png";
import hair_00046517 from "../images/hair/00046517.img/default.hairOverHead.png";
import hair_00046485 from "../images/hair/00046485.img/default.hairOverHead.png";
import hair_00046372 from "../images/hair/00046372.img/default.hairOverHead.png";
import hair_00043731 from "../images/hair/00043731.img/default.hairOverHead.png";
import hair_00033274 from "../images/hair/00033274.img/default.hairOverHead.png";
import hair_00033296 from "../images/hair/00033296.img/default.hairOverHead.png";
import hair_00043894 from "../images/hair/00043894.img/default.hairOverHead.png";
import hair_00045234 from "../images/hair/00045234.img/default.hairOverHead.png";
import hair_00045326 from "../images/hair/00045326.img/default.hairOverHead.png";
import hair_00035186 from "../images/hair/00035186.img/default.hairOverHead.png";

import pants_01062060 from "../images/pant/01062060.img/info.icon.png";
import pants_01062130 from "../images/pant/01062130.img/info.icon.png";
import pants_01062061 from "../images/pant/01062061.img/info.icon.png";
import pants_01062268 from "../images/pant/01062268.img/info.icon.png";
import pants_01062267 from "../images/pant/01062267.img/info.icon.png";
import pants_01062108 from "../images/pant/01062108.img/info.icon.png";
import pants_01062106 from "../images/pant/01062106.img/info.icon.png";
import pants_01062102 from "../images/pant/01062102.img/info.icon.png";
import pants_01062100 from "../images/pant/01062100.img/info.icon.png";
import pants_01062118 from "../images/pant/01062118.img/info.icon.png";
import pants_01062119 from "../images/pant/01062119.img/info.icon.png";
import pants_01062122 from "../images/pant/01062122.img/info.icon.png";
import pants_01062145 from "../images/pant/01062145.img/info.icon.png";
import pants_01062282 from "../images/pant/01062282.img/info.icon.png";
import pants_01062096 from "../images/pant/01062096.img/info.icon.png";
import pants_01062250 from "../images/pant/01062250.img/info.icon.png";
import pants_01062124 from "../images/pant/01062124.img/info.icon.png";
import pants_01062082 from "../images/pant/01062082.img/info.icon.png";
import pants_01062123 from "../images/pant/01062123.img/info.icon.png";
import pants_01062136 from "../images/pant/01062136.img/info.icon.png";
import pants_01062173 from "../images/pant/01062173.img/info.icon.png";
import pants_01062219 from "../images/pant/01062219.img/info.icon.png";
import pants_01062238 from "../images/pant/01062238.img/info.icon.png";
import pants_01062133 from "../images/pant/01062133.img/info.icon.png";
import pants_01062068 from "../images/pant/01062068.img/info.icon.png";
import pants_01062103 from "../images/pant/01062103.img/info.icon.png";
import pants_01062237 from "../images/pant/01062237.img/info.icon.png";
import pants_01062203 from "../images/pant/01062203.img/info.icon.png";
import pants_01062058 from "../images/pant/01062058.img/info.icon.png";
import pants_01062239 from "../images/pant/01062239.img/info.icon.png";

import coat_01042404 from "../images/coat/01042404.img/info.icon.png";
import coat_01042407 from "../images/coat/01042407.img/info.icon.png";
import coat_01042408 from "../images/coat/01042408.img/info.icon.png";
import coat_01042406 from "../images/coat/01042406.img/info.icon.png";
import coat_01042405 from "../images/coat/01042405.img/info.icon.png";
import coat_01042409 from "../images/coat/01042409.img/info.icon.png";
import coat_01042349 from "../images/coat/01042349.img/info.icon.png";
import coat_01042385 from "../images/coat/01042385.img/info.icon.png";
import coat_01042381 from "../images/coat/01042381.img/info.icon.png";
import coat_01042092 from "../images/coat/01042092.img/info.icon.png";
import coat_01042166 from "../images/coat/01042166.img/info.icon.png";
import coat_01042232 from "../images/coat/01042232.img/info.icon.png";
import coat_01040192 from "../images/coat/01040192.img/info.icon.png";
import coat_01042202 from "../images/coat/01042202.img/info.icon.png";
import coat_01042178 from "../images/coat/01042178.img/info.icon.png";
import coat_01042169 from "../images/coat/01042169.img/info.icon.png";
import coat_01042094 from "../images/coat/01042094.img/info.icon.png";
import coat_01042097 from "../images/coat/01042097.img/info.icon.png";
import coat_01042118 from "../images/coat/01042118.img/info.icon.png";
import coat_01042212 from "../images/coat/01042212.img/info.icon.png";
import coat_01042098 from "../images/coat/01042098.img/info.icon.png";
import coat_01042155 from "../images/coat/01042155.img/info.icon.png";
import coat_01042185 from "../images/coat/01042185.img/info.icon.png";
import coat_01042329 from "../images/coat/01042329.img/info.icon.png";
import coat_01042153 from "../images/coat/01042153.img/info.icon.png";
import coat_01042086 from "../images/coat/01042086.img/info.icon.png";
import coat_01042152 from "../images/coat/01042152.img/info.icon.png";
import coat_01042160 from "../images/coat/01042160.img/info.icon.png";
import coat_01042263 from "../images/coat/01042263.img/info.icon.png";
import coat_01042343 from "../images/coat/01042343.img/info.icon.png";

import shoe_01073216 from "../images/shoe/01073216.img/info.icon.png";
import shoe_01073453 from "../images/shoe/01073453.img/info.icon.png";
import shoe_01073192 from "../images/shoe/01073192.img/info.icon.png";
import shoe_01072943 from "../images/shoe/01072943.img/info.icon.png";
import shoe_01073323 from "../images/shoe/01073323.img/info.icon.png";
import shoe_01073341 from "../images/shoe/01073341.img/info.icon.png";
import shoe_01073308 from "../images/shoe/01073308.img/info.icon.png";
import shoe_01073312 from "../images/shoe/01073312.img/info.icon.png";
import shoe_01073465 from "../images/shoe/01073465.img/info.icon.png";
import shoe_01073037 from "../images/shoe/01073037.img/info.icon.png";
import shoe_01073058 from "../images/shoe/01073058.img/info.icon.png";
import shoe_01073150 from "../images/shoe/01073150.img/info.icon.png";
import shoe_01073196 from "../images/shoe/01073196.img/info.icon.png";
import shoe_01073262 from "../images/shoe/01073262.img/info.icon.png";
import shoe_01073497 from "../images/shoe/01073497.img/info.icon.png";
import shoe_01073237 from "../images/shoe/01073237.img/info.icon.png";
import shoe_01073404 from "../images/shoe/01073404.img/info.icon.png";
import shoe_01072820 from "../images/shoe/01072820.img/info.icon.png";
import shoe_01073156 from "../images/shoe/01073156.img/info.icon.png";
import shoe_01072695 from "../images/shoe/01072695.img/info.icon.png";
import shoe_01072696 from "../images/shoe/01072696.img/info.icon.png";
import shoe_01072697 from "../images/shoe/01072697.img/info.icon.png";

import gloves_01082002 from "../images/gloves/01082002.img/info.icon.png";
import gloves_01082040 from "../images/gloves/01082040.img/info.icon.png";
import gloves_01082041 from "../images/gloves/01082041.img/info.icon.png";
import gloves_01082385 from "../images/gloves/01082385.img/info.icon.png";
import gloves_01082492 from "../images/gloves/01082492.img/info.icon.png";
import gloves_01082491 from "../images/gloves/01082491.img/info.icon.png";
import gloves_01082490 from "../images/gloves/01082490.img/info.icon.png";
import gloves_01081014 from "../images/gloves/01081014.img/info.icon.png";

import accessory_01022064 from "../images/accessory/01022064.img/info.icon.png";
import accessory_01022223 from "../images/accessory/01022223.img/info.icon.png";
import accessory_01022247 from "../images/accessory/01022247.img/info.icon.png";
import accessory_01022264 from "../images/accessory/01022264.img/info.icon.png";
import accessory_01022270 from "../images/accessory/01022270.img/info.icon.png";
import accessory_01022282 from "../images/accessory/01022282.img/info.icon.png";
import accessory_01022296 from "../images/accessory/01022296.img/info.icon.png";

import cape_01102155 from "../images/cape/01102155.img/info.icon.png";
import cape_01102374 from "../images/cape/01102374.img/info.icon.png";
import cape_01102911 from "../images/cape/01102911.img/info.icon.png";
import cape_01102601 from "../images/cape/01102601.img/info.icon.png";
import cape_01102668 from "../images/cape/01102668.img/info.icon.png";
import cape_01102778 from "../images/cape/01102778.img/info.icon.png";
import cape_01102695 from "../images/cape/01102695.img/info.icon.png";
import cape_01102157 from "../images/cape/01102157.img/info.icon.png";
import cape_01102221 from "../images/cape/01102221.img/info.icon.png";
import cape_01102975 from "../images/cape/01102975.img/info.icon.png";
import cape_01102808 from "../images/cape/01102808.img/info.icon.png";
import cape_01102111 from "../images/cape/01102111.img/info.icon.png";
import cape_01103047 from "../images/cape/01103047.img/info.icon.png";
import cape_01102945 from "../images/cape/01102945.img/info.icon.png";

export const Male = {
  skin: {
    path: "stand1.0.body.png",
    path2: "front.head.png",
    path3: "stand1.0.arm.png",
    elements: [
      { id: 0, name: "00002000", value: "Yellow Skin", weight: 100 },
      { id: 1, name: "00002001", value: "Tan Skin", weight: 75 },
      { id: 2, name: "00002002", value: "Brown Skin", weight: 50 },
      { id: 3, name: "00002003", value: "Light Skin", weight: 30 },
      { id: 4, name: "00002004", value: "White Skin", weight: 10 },
      { id: 5, name: "00002005", value: "Green Skin", weight: 5 },
    ],
  },
  face: {
    path: "default.face.png",
    elements: [
      { id: 0, name: "00025820", image: face_00025820, value: "Spoiled Face", weight: 100 },
      { id: 1, name: "00025825", image: face_00025825, value: "Curious Face", weight: 90 },
      { id: 2, name: "00026017", image: face_00026017, value: "Wow Face", weight: 85 },
      { id: 4, name: "00026049", image: face_00026049, value: "Elwin Face", weight: 80 },
      { id: 5, name: "00020840", image: face_00020840, value: "Piercing Gaze", weight: 75 },
      { id: 6, name: "00020846", image: face_00020846, value: "Puppy Eyes Face", weight: 70 },
      { id: 7, name: "00020842", image: face_00020842, value: "Android Face", weight: 65 },
      { id: 8, name: "00020868", image: face_00020868, value: "Teary-Eyed Face", weight: 60 },
      { id: 9, name: "00020865", image: face_00020865, value: "Charismatic Face", weight: 55 },
      { id: 10, name: "00023211", image: face_00023211, value: "Smartie Face", weight: 50 },
      { id: 11, name: "00025222", image: face_00025222, value: "Straight Face", weight: 45 },
      { id: 12, name: "00025217", image: face_00025217, value: "Lucid Face", weight: 40 },
      { id: 13, name: "00025241", image: face_00025241, value: "Apollo Face", weight: 35 },
      { id: 3, name: "00025221", image: face_00025221, value: "Hangover Face", weight: 30 },
      { id: 14, name: "00025243", image: face_00025243, value: "Enamored Face", weight: 25 },
      { id: 15, name: "00025372", image: face_00025372, value: "Green/Red Ark Face", weight: 20 },
      { id: 16, name: "00025772", image: face_00025772, value: "White/Red Ark Face", weight: 16 },
      { id: 17, name: "00027613", image: face_00027613, value: "Very Positive Face", weight: 14 },
      { id: 18, name: "00020072", image: face_00020072, value: "Purple Flame Face", weight: 12 },
      { id: 19, name: "00020071", image: face_00020071, value: "Orange Flame Face", weight: 10 },
      { id: 20, name: "00025011", image: face_00025011, value: "Starry-Eyed Face", weight: 8 },
      { id: 21, name: "00021250", image: face_00021250, value: "Demon Face", weight: 6 },
      { id: 22, name: "00050652", image: face_00050652, value: "Cynical Face", weight: 4 },
      { id: 23, name: "00025004", image: face_00025004, value: "Damien Face", weight: 2 },
    ],
  },
  hair: {
    path: "default.hairOverHead.png",
    elements: [
      // COMMON
      { id: 1, name: "00030020", image: hair_00030020, value: "Unkempt Hair", weight: 100 },
      { id: 2, name: "00046717", image: hair_00046717, value: "Dark Miro Hair", weight: 90 },
      { id: 3, name: "00046080", image: hair_00046080, value: "Haystack Hairdo", weight: 85 },
      { id: 4, name: "00046897", image: hair_00046897, value: "White Bowl Hair", weight: 80 },
      { id: 5, name: "00045305", image: hair_00045305, value: "Black Bowl Hair", weight: 75 },
      { id: 6, name: "00033371", image: hair_00033371, value: "Red Layered Wave Hair", weight: 70 },
      { id: 7, name: "00043273", image: hair_00043273, value: "Blonde Spikey Hair", weight: 65 },
      { id: 8, name: "00036790", image: hair_00036790, value: "Star Wave hair", weight: 60 },
      { id: 9, name: "00043180", image: hair_00043180, value: "Black Street Punk Hair", weight: 55 },
      { id: 10, name: "00046850", image: hair_00046850, value: "Elegant Black Hair", weight: 50 },

      // RARE
      { id: 11, name: "00043660", image: hair_00043660, value: "Elegant White Hair", weight: 47 },
      { id: 12, name: "00046734", image: hair_00046734, value: "Green Spikey Hair", weight: 44 },
      { id: 13, name: "00046881", image: hair_00046881, value: "Velvet Hair", weight: 41 },
      { id: 14, name: "00046810", image: hair_00046810, value: "Namo Hair", weight: 38 },
      { id: 15, name: "00046685", image: hair_00046685, value: "Powerful Hair", weight: 35 },
      { id: 16, name: "00046910", image: hair_00046910, value: "Typhoon Hair", weight: 32 },
      { id: 17, name: "00044101", image: hair_00044101, value: "Apollo Hair", weight: 29 },
      { id: 18, name: "00040590", image: hair_00040590, value: "Black Ibaraki Hair", weight: 26 },
      { id: 19, name: "00046741", image: hair_00046741, value: "Soulmate Hair", weight: 23 },
      { id: 20, name: "00046517", image: hair_00046517, value: "Goggles and Spikes Hair", weight: 20 },

      // SUPER RARE
      { id: 21, name: "00046485", image: hair_00046485, value: "Belial Hair", weight: 18 },
      { id: 22, name: "00046372", image: hair_00046372, value: "Ombre Shag", weight: 16 },
      { id: 23, name: "00043731", image: hair_00043731, value: "Illium Hair", weight: 14 },
      { id: 24, name: "00033274", image: hair_00033274, value: "Green Spiky Bangs Hair", weight: 12 },
      { id: 25, name: "00033296", image: hair_00033296, value: "Purple Updo", weight: 10 },
      { id: 26, name: "00043894", image: hair_00043894, value: "Galaxy Hair", weight: 8 },
      { id: 27, name: "00045234", image: hair_00045234, value: "Foggy Flawless Hair", weight: 6 },
      { id: 28, name: "00045326", image: hair_00045326, value: "Bad Boy Hair", weight: 4 },
      { id: 29, name: "00035186", image: hair_00035186, value: "Purple Victory Rider Hair", weight: 2 },
    ],
  },
  pants: {
    path: "stand1.0.pants.png",
    elements: [
      { id: 0, name: "01062060", image: pants_01062060, value: "Blue Skinny Jeans", weight: 100 },
      { id: 1, name: "01062130", image: pants_01062130, value: "Blue Spotted Shorts", weight: 85 },
      { id: 2, name: "01062061", image: pants_01062061, value: "Olive Skinny Jeans", weight: 70 },
      { id: 3, name: "01062268", image: pants_01062268, value: "Blue Thermal Pants", weight: 60 },
      { id: 4, name: "01062267", image: pants_01062267, value: "Green Thermal Pants", weight: 50 },
      { id: 5, name: "01062108", image: pants_01062108, value: "Washed Jeans", weight: 40 },
      { id: 6, name: "01062106", image: pants_01062106, value: "Bunny Frill Pants", weight: 38 },
      { id: 7, name: "01062102", image: pants_01062102, value: "Star Jeans", weight: 36 },
      { id: 8, name: "01062100", image: pants_01062100, value: "Rolled Up Jeans", weight: 34 },
      { id: 9, name: "01062118", image: pants_01062118, value: "Stone Washed Jeans", weight: 32 },
      { id: 10, name: "01062119", image: pants_01062119, value: "Techno Pants", weight: 30 },
      { id: 11, name: "01062122", image: pants_01062122, value: "Plaid-Cuffed Jeans", weight: 28 },
      { id: 12, name: "01062145", image: pants_01062145, value: "Rainbow Leggings", weight: 26 },
      { id: 13, name: "01062282", image: pants_01062282, value: "Purple Shorts", weight: 24 },
      { id: 14, name: "01062096", image: pants_01062096, value: "Linen Trousers", weight: 22 },
      { id: 15, name: "01062250", image: pants_01062250, value: "Tennis Shorts", weight: 20 },
      { id: 16, name: "01062124", image: pants_01062124, value: "Saruel Pants", weight: 18 },
      { id: 17, name: "01062082", image: pants_01062082, value: "Warm-up Pants", weight: 16 },
      { id: 18, name: "01062123", image: pants_01062123, value: "High-Rider Pants", weight: 14 },
      { id: 19, name: "01062136", image: pants_01062136, value: "Layered Denim Pants", weight: 12 },
      { id: 20, name: "01062173", image: pants_01062173, value: "Funky Shorts", weight: 10 },
      { id: 21, name: "01062219", image: pants_01062219, value: "Colorful Blue Pants", weight: 9 },
      { id: 22, name: "01062238", image: pants_01062238, value: "Amour Shorts", weight: 8 },
      { id: 23, name: "01062133", image: pants_01062133, value: "Star Trainer Pants", weight: 7 }, //FIX
      { id: 24, name: "01062068", image: pants_01062068, value: "Rainbow Shorts", weight: 6 },
      { id: 25, name: "01062103", image: pants_01062103, value: "Baggy Glow-in-the-Dark Pants", weight: 5 },
      { id: 26, name: "01062237", image: pants_01062237, value: "Fashion Shorts", weight: 4 },
      { id: 27, name: "01062203", image: pants_01062203, value: "Otherworldly Slacks", weight: 3 },
      { id: 28, name: "01062058", image: pants_01062058, value: "Inferno Jeans", weight: 2 },
      { id: 29, name: "01062239", image: pants_01062239, value: "Defi Shorts", weight: 1 },
    ],
  },
  coat: {
    path: "stand1.0.mail.png",
    path2: "stand1.0.mailArm.png",
    elements: [
      // COMMON
      { id: 0, name: "01042404", image: coat_01042404, value: "Orange Sweater", weight: 100 },
      { id: 1, name: "01042407", image: coat_01042407, value: "Blue Sweater", weight: 90 },
      { id: 2, name: "01042408", image: coat_01042408, value: "Purple Sweater", weight: 80 },
      { id: 3, name: "01042406", image: coat_01042406, value: "Green Sweater", weight: 70 },
      { id: 4, name: "01042405", image: coat_01042405, value: "Orange Sweater", weight: 60 },
      { id: 5, name: "01042409", image: coat_01042409, value: "Midnight Sweater", weight: 50 },
      { id: 6, name: "01042349", image: coat_01042349, value: "Black Hoodie", weight: 43 },
      { id: 7, name: "01042385", image: coat_01042385, value: "Trendy Denim Jacket", weight: 41 },
      { id: 8, name: "01042381", image: coat_01042381, value: "Khaki Field Coat", weight: 39 },
      { id: 9, name: "01042092", image: coat_01042092, value: "Pelvis Hoodie", weight: 37 },
      { id: 10, name: "01042166", image: coat_01042166, value: "Leather Biker Jacket", weight: 35 },
      { id: 11, name: "01042232", image: coat_01042232, value: "Bat Costume Sweater", weight: 33 },
      { id: 12, name: "01040192", image: coat_01040192, value: "Green Bunny T-Shirt", weight: 31 },
      { id: 13, name: "01042202", image: coat_01042202, value: "Penguin T-Shirt", weight: 29 },
      { id: 14, name: "01042178", image: coat_01042178, value: "Puppy T-Shirt", weight: 27 },
      { id: 15, name: "01042169", image: coat_01042169, value: "Rainbow Tie-Dye T-Shirt", weight: 25 },
      { id: 16, name: "01042094", image: coat_01042094, value: "Orange Snowflake Sweater", weight: 23 },
      { id: 17, name: "01042097", image: coat_01042097, value: "Print Layered Hoodie", weight: 21 },
      { id: 18, name: "01042118", image: coat_01042118, value: "Red Checkered T-Shirt", weight: 19 },
      { id: 19, name: "01042212", image: coat_01042212, value: "Blue Spring Jacket", weight: 17 },
      { id: 20, name: "01042098", image: coat_01042098, value: "Camo Hooded Jacket", weight: 15 },
      { id: 21, name: "01042155", image: coat_01042155, value: "Sky Rider Jacket", weight: 13 },
      { id: 22, name: "01042185", image: coat_01042185, value: "Street Gear", weight: 11 },
      { id: 23, name: "01042329", image: coat_01042329, value: "Sweet Summer T-Shirt", weight: 9 },
      { id: 24, name: "01042153", image: coat_01042153, value: "Red Plaid Duffle Coat", weight: 7 },
      { id: 25, name: "01042086", image: coat_01042086, value: "Tourist T-Shirt", weight: 5 },
      { id: 26, name: "01042152", image: coat_01042152, value: "Rainbow Knitted Top", weight: 4 },
      { id: 27, name: "01042160", image: coat_01042160, value: "Designer Hoodie", weight: 3 },
      { id: 28, name: "01042263", image: coat_01042263, value: "Funky Jumper", weight: 2 },
      { id: 29, name: "01042343", image: coat_01042343, value: "Chain Hoodie", weight: 1 },
    ],
  },
  shoe: {
    path: "stand1.0.shoes.png",
    elements: [
      { id: 0, name: "01073216", image: shoe_01073216, value: "Pop Star Shoes", weight: 100 },
      { id: 1, name: "01073453", image: shoe_01073453, value: "Autumn Shoes", weight: 75 },
      { id: 2, name: "01073192", image: shoe_01073192, value: "Explorer Boots", weight: 50 },
      { id: 3, name: "01072943", image: shoe_01072943, value: "Imperial Shoes", weight: 47 },
      { id: 4, name: "01073323", image: shoe_01073323, value: "Fitness Trainers", weight: 44 },
      { id: 5, name: "01073341", image: shoe_01073341, value: "Hunter Shoes", weight: 41 },
      { id: 6, name: "01073308", image: shoe_01073308, value: "Trainers", weight: 38 },
      { id: 7, name: "01073312", image: shoe_01073312, value: "Hunting Shoes", weight: 35 },
      { id: 8, name: "01073465", image: shoe_01073465, value: "Forest Shoes", weight: 32 },
      { id: 9, name: "01073037", image: shoe_01073037, value: "Cutie Bunny Shoes", weight: 29, z: true },
      { id: 10, name: "01073058", image: shoe_01073058, value: "Fish Shoes", weight: 26, z: true },
      { id: 11, name: "01073150", image: shoe_01073150, value: "Chicken Cutie Shoes", weight: 23, z: true },
      { id: 12, name: "01073196", image: shoe_01073196, value: "Yellow Chick Shoes", weight: 20, z: true },
      { id: 13, name: "01073262", image: shoe_01073262, value: "Shiba Sandals", weight: 17, z: true },
      { id: 14, name: "01073497", image: shoe_01073497, value: "Love Sandals", weight: 14, z: true },
      { id: 15, name: "01073237", image: shoe_01073237, value: "Animal Patch Sandals", weight: 11 },
      { id: 16, name: "01073404", image: shoe_01073404, value: "Bright Sneakers", weight: 8 },
      { id: 17, name: "01072820", image: shoe_01072820, value: "Funky Boots", weight: 5, z: true },
      { id: 18, name: "01073156", image: shoe_01073156, value: "Colorful Beach Sandals", weight: 4, z: true },
      { id: 19, name: "01072695", image: shoe_01072695, value: "Kaiser Boots", weight: 3 },
      { id: 20, name: "01072696", image: shoe_01072696, value: "Luminous Boots", weight: 2 },
      { id: 21, name: "01072697", image: shoe_01072697, value: "Angelic Boots", weight: 1 },
    ],
  },
  accessory: {
    path: "default.default.png",
    elements: [
      { id: 1, name: "01022064", image: accessory_01022064, value: "Big Red Glasses", weight: 30 },
      { id: 2, name: "01022247", image: accessory_01022247, value: "Black Sunglasses", weight: 20 },
      { id: 3, name: "01022296", image: accessory_01022296, value: "Sunset Sunglasses", weight: 15 },
      { id: 4, name: "01022223", image: accessory_01022223, value: "LED Sunglasses", weight: 10 },
      { id: 5, name: "01022270", image: accessory_01022270, value: "Oversized Sunglasses", weight: 7.5 },
      { id: 6, name: "01022264", image: accessory_01022264, value: "Foxwit Glasses", weight: 5 },
      { id: 7, name: "01022282", image: accessory_01022282, value: "33 Glasses", weight: 2.5 },
    ],
  },
  gloves: {
    path2: "stand1.0.rGlove.png",
    path: "stand1.0.lGlove.png",
    elements: [
      { id: 1, name: "01082002", image: gloves_01082002, value: "White gloves", weight: 20 },
      { id: 2, name: "01082040", image: gloves_01082040, value: "Red Boxing Gloves", weight: 16 },
      { id: 3, name: "01082041", image: gloves_01082041, value: "Blue Boxing Gloves", weight: 12 },
      { id: 4, name: "01082385", image: gloves_01082385, value: "Black Schult Gloves", weight: 10 },
      { id: 5, name: "01082492", image: gloves_01082492, value: "Angelic Gloves", weight: 8 },
      { id: 6, name: "01082491", image: gloves_01082491, value: "Luminous Gloves", weight: 6 },
      { id: 7, name: "01082490", image: gloves_01082490, value: "Kaiser Gloves", weight: 4 },
      { id: 8, name: "01081014", image: gloves_01081014, value: "Whip Cream Gloves", weight: 2 },
    ],
  },
  cape: {
    path: "stand1.0.cape.png",
    elements: [
      { id: 0, name: "01102155", image: cape_01102155, value: "Bunny Backpack Cape", weight: 14 },
      { id: 1, name: "01102374", image: cape_01102374, value: "Monkey Cape", weight: 13 },
      { id: 2, name: "01102911", image: cape_01102911, value: "Fox Tail Cape", weight: 12 },
      { id: 3, name: "01102601", image: cape_01102601, value: "Sword Cape", weight: 11 },
      { id: 4, name: "01102668", image: cape_01102668, value: "Dark Angel Wings Cape", weight: 10 },
      { id: 5, name: "01102778", image: cape_01102778, value: "Lollipop Cape", weight: 9 },
      { id: 6, name: "01102695", image: cape_01102695, value: "Fox Spirit Cape", weight: 8 },
      { id: 7, name: "01102157", image: cape_01102157, value: "Puppet Cape", weight: 7 },
      { id: 8, name: "01102221", image: cape_01102221, value: "Pluto Flame Cape", weight: 6 },
      { id: 9, name: "01102975", image: cape_01102975, value: "Cat Balloon Cape", weight: 5 },
      { id: 10, name: "01102808", image: cape_01102808, value: "Mouse Couple Cape", weight: 4 },
      { id: 11, name: "01102111", image: cape_01102111, value: "Elephant Balloon Cape", weight: 3 },
      { id: 12, name: "01103047", image: cape_01103047, value: "Corgi Cape", weight: 2, z: true },
      { id: 13, name: "01102945", image: cape_01102945, value: "Kitty Cape", weight: 1, z: true },
    ],
  },
};

export const Female = {
  skin: {
    //! # 0

    path: "stand1.0.body.png",
    path2: "front.head.png",
    path3: "stand1.0.arm.png",
    elements: [
      { id: 0, name: "00002000", value: "Yellow Skin", weight: 100 },
      { id: 1, name: "00002001", value: "Tan Skin", weight: 75 },
      { id: 2, name: "00002002", value: "Brown Skin", weight: 50 },
      { id: 3, name: "00002003", value: "Light Skin", weight: 30 },
      { id: 4, name: "00002004", value: "White Skin", weight: 10 },
      { id: 5, name: "00002005", value: "Green Skin", weight: 5 },
    ],
  },
  face: {
    //! # 1
    path: "default.face.png",
    elements: [
      { id: 0, name: "00025820", value: "Spoiled Face", weight: 100 },
      { id: 1, name: "00025825", value: "Curious Face", weight: 90 },
      { id: 2, name: "00026017", value: "Wow Face", weight: 85 },
      { id: 3, name: "00025221", value: "Hangover Face", weight: 80 },
      { id: 4, name: "00024610", value: "Elegent Face", weight: 75 },
      { id: 5, name: "00020840", value: "Piercing Gaze Face", weight: 70 },
      { id: 6, name: "00020846", value: "Puppy Eyes Face", weight: 65 },
      { id: 7, name: "00023784", value: "Doll Face", weight: 60 },
      { id: 8, name: "00021287", value: "Hayato Face", weight: 55 },
      { id: 9, name: "00023038", value: "Dylan Face", weight: 50 },
      { id: 10, name: "00023211", value: "Smartie Face", weight: 45 },
      { id: 11, name: "00024710", value: "Curious Face", weight: 40 },
      { id: 12, name: "00025217", value: "Lucid Face", weight: 35 },
      { id: 13, name: "00021062", value: "Sulky Face", weight: 30 },
      { id: 14, name: "00025243", value: "Enamored Face", weight: 25 },
      { id: 15, name: "00025372", value: "Green/Red Eye Face", weight: 20 },
      { id: 16, name: "00027606", value: "Starry Summer Night Face", weight: 16 },
      { id: 17, name: "00027613", value: "Very Positive Face", weight: 14 },
      { id: 18, name: "00051746", value: "Shining Eyes Face", weight: 12 },
      { id: 19, name: "00025011", value: "Starry-Eyed Face", weight: 10 },
      { id: 20, name: "00050000", value: "Sky Face", weight: 8 },
      { id: 21, name: "00051101", value: "Undisturbed Face", weight: 6 },
      { id: 22, name: "00050001", value: "Brooding Face", weight: 4 },
      { id: 23, name: "00027075", value: "Lively Boy Face", weight: 2 },
    ],
  },
  hair: {
    //! # 2
    path: "default.hairOverHead.png",
    elements: [
      // COMMON

      { id: 1, name: "00038687", value: "Brown Airhead Hair", weight: 100 },
      { id: 2, name: "00038437", value: "Brown Scarf Hair", weight: 90 },
      { id: 3, name: "00038862", value: "Orange Irena Buddy Hair", weight: 85 },
      { id: 4, name: "00041757", value: "Brown Morning Hair", weight: 80 },
      { id: 5, name: "00041170", value: "Black Kanna Hair", weight: 75 },
      { id: 6, name: "00038660", value: "Chic Ponytail", weight: 70 },
      { id: 7, name: "00039483", value: "Akechi Hair", weight: 65 },
      { id: 8, name: "00038691", value: "Red Sweet Creme Hair", weight: 60 },
      { id: 9, name: "00038570", value: "Black Rainbow Hair", weight: 55 },
      { id: 10, name: "00038400", value: "Black Sweet Honey Hair", weight: 50 },

      // RARE
      { id: 11, name: "00038386", value: "Purple Soft Curl Hair", weight: 47 },
      { id: 12, name: "00038426", value: "Purple Roly Poly Hair", weight: 44 },
      { id: 13, name: "00038585", value: "Blue Marigold Hair", weight: 41 },
      { id: 14, name: "00038734", value: "Tangy Top Hair", weight: 38 },
      { id: 15, name: "00038745", value: "Blue Bestie Girl Hair", weight: 35 },
      { id: 16, name: "00038793", value: "Blonde Voluminous Pigtails", weight: 32 },
      { id: 17, name: "00038811", value: "Red Spring Flower Hair", weight: 29 },
      { id: 18, name: "00038491", value: "Red Sugarbang Hair", weight: 26 },
      { id: 19, name: "00038884", value: "Green Cygnus Buddy Hair", weight: 23 },
      { id: 20, name: "00041081", value: "Red Sunday Best Girl Hair", weight: 20 },

      // SUPER RARE
      { id: 21, name: "00038505", value: "Blue Forest Wave", weight: 18 },
      { id: 22, name: "00038766", value: "Purple Vella Hair", weight: 16 },
      { id: 23, name: "00038935", value: "Blue Royal Twin Tails Hair", weight: 14 },
      { id: 24, name: "00040871", value: "Anastasia Hair", weight: 12 },
      { id: 25, name: "00041466", value: "Purple Chic Kitten Hair", weight: 10 },
      { id: 26, name: "00038566", value: "Purple Glow Butterfly Hair", weight: 8 },
      { id: 27, name: "00041570", value: "Black Forest Fairy", weight: 6 },
      { id: 28, name: "00041825", value: "Blue Pudding Hair", weight: 4 },
      { id: 29, name: "00040903", value: "Kat Hair", weight: 2 },
    ],
  },
  pant: {
    //! # 3
    path: "stand1.0.pants.png",
    elements: [
      // COMMON

      { id: 0, name: "01061147", value: "Black Pants", weight: 100 },
      { id: 1, name: "01062110", value: "Baby Pink Pants", weight: 85 },
      { id: 2, name: "01062130", value: "Blue Spotted Shorts", weight: 70 },
      { id: 3, name: "01062268", value: "Cool-Headed Thermal Bottom", weight: 60 },
      { id: 4, name: "01062267", value: "Fresh Thermal Bottom", weight: 50 },

      // RARE
      { id: 5, name: "01062209", value: "Mini Bunny Pants", weight: 40 },
      { id: 6, name: "01062179", value: "Little Bunny Pants", weight: 38 },
      { id: 7, name: "01062126", value: "Pink Sprite Pants", weight: 36 },
      { id: 8, name: "01060189", value: "Smile Seed Pants", weight: 34 },
      { id: 9, name: "01062067", value: "Summer Capris", weight: 32 },
      { id: 10, name: "01062251", value: "Tennis Bottom", weight: 30 },
      { id: 11, name: "01061141", value: "Tartan Skirt", weight: 28 },
      { id: 12, name: "01062067", value: "Summer Capris", weight: 26 },
      { id: 13, name: "01062175", value: "Pink Skinny Jeans", weight: 24 },
      { id: 14, name: "01062130", value: "Blue Spotted Shorts", weight: 22 },
      { id: 15, name: "01061148", value: "Pink Frill Swim Skirt", weight: 20 },
      { id: 16, name: "01062153", value: "Vacation Denim Pants", weight: 18 },
      { id: 17, name: "01062221", value: "Teddy Picnic Pants", weight: 16 },
      { id: 18, name: "01062185", value: "Violet Dot Jeans", weight: 14 },
      { id: 19, name: "01062136", value: "Layered Denim Pants", weight: 12 },

      // SUPER RARE
      { id: 20, name: "01062139", value: "Knee Socks Pants", weight: 10 },
      { id: 21, name: "01062219", value: "Colorful Blue Pants", weight: 9 },
      { id: 22, name: "01062238", value: "Amour Pants", weight: 8 },
      { id: 23, name: "01061212", value: "White Hot Pants", weight: 7 },
      { id: 24, name: "01061143", value: "Amorian Pink Skirt", weight: 6 },
      { id: 25, name: "01061170", value: "Bright Frilly Shorts", weight: 5 },
      { id: 26, name: "01062216", value: "Mismatched Shorts", weight: 4 },
      { id: 27, name: "01062203", value: "Otherworldly Slacks", weight: 3 },
      { id: 28, name: "01062058", value: "Inferno Jeans", weight: 2 },
      { id: 29, name: "01062239", value: "Defi Pants", weight: 1 },
    ],
  },
  coat: {
    //! # 4
    path: "stand1.0.mail.png",
    path2: "stand1.0.mailArm.png",
    elements: [
      // COMMON
      { id: 0, name: "01042404", value: "Orange Sweater", weight: 100 },
      { id: 1, name: "01042407", value: "Blue Sweater", weight: 90 },
      { id: 2, name: "01042408", value: "Purple Sweater", weight: 80 },
      { id: 3, name: "01042406", value: "Green Sweater", weight: 70 },
      { id: 4, name: "01042405", value: "Orange Sweater", weight: 60 },
      { id: 5, name: "01042409", value: "Midnight Sweater", weight: 45 },
      { id: 6, name: "01042277", value: "Star T-Shirt", weight: 43 },
      { id: 7, name: "01042271", value: "Meow T-shirt", weight: 41 },
      { id: 8, name: "01042178", value: "Puppy T-Shirt", weight: 39 },
      { id: 9, name: "01042187", value: "Pink Sweater", weight: 37 },
      { id: 10, name: "01042353", value: "Slime T-Shirt", weight: 35 },
      { id: 11, name: "01042125", value: "Bunny Bag Longsleeve", weight: 33 },

      // RARE
      { id: 12, name: "01048001", value: "Bunny Love T-Shirt", weight: 31 },
      { id: 13, name: "01048002", value: "Carrot T-shirt", weight: 29 },
      { id: 14, name: "01042337", value: "Teddy Picnic T-Shirt", weight: 27 },
      { id: 15, name: "01042336", value: "Corny T-shirt", weight: 25 },
      { id: 16, name: "01042333", value: "Pink Kitty Sweatshirt", weight: 23 },
      { id: 17, name: "01042269", value: "Ribbon Days T-shirt", weight: 21 },
      { id: 18, name: "01042267", value: "Exciting Hoodie", weight: 19 },
      { id: 19, name: "01042265", value: "Strawberry T-Shirt", weight: 17 },
      { id: 20, name: "01042260", value: "Loose Fit Sweater", weight: 15 },
      { id: 21, name: "01042215", value: "Jester Sweater", weight: 13 },
      { id: 22, name: "01042207", value: "Star Trainer Jacket", weight: 11 },
      { id: 23, name: "01042154", value: "Bohemian Hooded Jacket", weight: 9 },
      { id: 24, name: "01042360", value: "Cherry Rabbit Hood", weight: 7 },

      // SUPER RARE
      { id: 25, name: "01042293", value: "Guardian Shirt", weight: 5 },
      { id: 26, name: "01042235", value: "Rabbit T-shirt", weight: 4 },
      { id: 27, name: "01042189", value: "Lamb Wool T-shirt", weight: 3 },
      { id: 28, name: "01042337", value: "Teddy Picnic T-Shirt", weight: 2 },
      { id: 29, name: "01042343", value: "Chain Hoodie", weight: 1 },
    ],
  },
  arm: {
    //! # 5
    path: "stand1.0.arm.png",
    elements: [
      { id: 0, name: "00002000", value: "Yellow Skin", weight: 100 },
      { id: 1, name: "00002001", value: "Tan Skin", weight: 75 },
      { id: 2, name: "00002002", value: "Brown Skin", weight: 50 },
      { id: 3, name: "00002003", value: "Light Skin", weight: 30 },
      { id: 4, name: "00002004", value: "White Skin", weight: 10 },
      { id: 5, name: "00002005", value: "Green Skin", weight: 5 },
    ],
  },
  shoe: {
    //! # 6
    path: "stand1.0.shoes.png",
    elements: [
      //COMMON
      { id: 0, name: "01070118", value: "Leather Shoes", weight: 100 },
      { id: 1, name: "01070070", value: "School Shoes", weight: 75 },

      // RARE
      { id: 2, name: "01070080", value: "White Loafers", weight: 50 },
      { id: 3, name: "01070114", value: "Blue Sandals", weight: 47 },
      { id: 4, name: "01071083", value: "Flower Shoes", weight: 44 },
      { id: 5, name: "01071031", value: "Angel Wing Shoes", weight: 41 },
      { id: 6, name: "01073308", value: "Trainers", weight: 38 },
      { id: 7, name: "01071044", value: "Bright Flats", weight: 35 },
      { id: 8, name: "01073465", value: "Forest Shoes", weight: 32 },
      { id: 9, name: "01073037", value: "Bunny Shoes", weight: 29, z: true },
      { id: 10, name: "01073058", value: "Fish Shoes", weight: 26, z: true },
      { id: 11, name: "01073150", value: "Chicken Sandals", weight: 23, z: true },
      { id: 12, name: "01073196", value: "Young Chick Sandals", weight: 20, z: true },
      { id: 13, name: "01073262", value: "Shiba Sandals", weight: 17, z: true },
      { id: 14, name: "01073497", value: "Love Sandals", weight: 14, z: true },
      { id: 15, name: "01073237", value: "Animal Patch Sandals", weight: 11 },
      { id: 16, name: "01073404", value: "Bright Sneakers", weight: 8 },

      // SUPER RARE
      { id: 17, name: "01071085", value: "Cloud Boots", weight: 5, z: true },
      { id: 18, name: "01073156", value: "Colorful Beach Sandals", weight: 4, z: true },
      { id: 19, name: "01071104", value: "Dance Shoes", weight: 3 },
      { id: 20, name: "01072696", value: "Luminous Boots", weight: 2 },
      { id: 21, name: "01072697", value: "Angelic Boots", weight: 1 },
    ],
  },
  accessory: {
    //! # 7
    path: "default.default.png",
    elements: [
      { id: 1, name: "01022064", image: accessory_01022064, value: "Big Red Glasses", weight: 30 },
      { id: 2, name: "01022247", image: accessory_01022247, value: "Black Sunglasses", weight: 20 },
      { id: 3, name: "01022296", image: accessory_01022296, value: "Sunset Sunglasses", weight: 15 },
      { id: 4, name: "01022223", image: accessory_01022223, value: "LED Sunglasses", weight: 10 },
      { id: 5, name: "01022270", image: accessory_01022270, value: "Oversized Sunglasses", weight: 7.5 },
      { id: 6, name: "01022264", image: accessory_01022264, value: "Foxwit Glasses", weight: 5 },
      { id: 7, name: "01022282", image: accessory_01022282, value: "33 Glasses", weight: 2.5 },
    ],
  },
  gloves: {
    //! # 8
    path2: "stand1.0.rGlove.png",
    path: "stand1.0.lGlove.png",
    elements: [
      { id: 1, name: "01082002", value: "White Gloves", weight: 20 },
      { id: 2, name: "01082720", value: "Pink Gloves", weight: 16 },
      { id: 3, name: "01082666", value: "White Paw Gloves", weight: 12 },
      { id: 4, name: "01080008", value: "Whip Cream Gloves", weight: 10 },
      { id: 5, name: "01082492", value: "Glowing Pink Gloves", weight: 8 },
      { id: 6, name: "01082290", value: "Angelic Gloves", weight: 6 },
      { id: 7, name: "01082490", value: "Kaiser Gloves", weight: 4 },
      { id: 8, name: "01082547", value: "Tyrant Gloves", weight: 2 },
    ],
  },
  cape: {
    //! # 9
    path: "stand1.0.cape.png",
    elements: [
      { id: 0, name: "01102155", image: cape_01102155, value: "Bunny Backpack Cape", weight: 14 },
      { id: 1, name: "01102374", image: cape_01102374, value: "Monkey Cape", weight: 13 },
      { id: 2, name: "01102911", image: cape_01102911, value: "Fox Tail Cape", weight: 12 },
      { id: 3, name: "01102601", image: cape_01102601, value: "Sword Cape", weight: 11 },
      { id: 4, name: "01102668", image: cape_01102668, value: "Dark Angel Wings Cape", weight: 10 },
      { id: 5, name: "01102778", image: cape_01102778, value: "Lollipop Cape", weight: 9 },
      { id: 6, name: "01102695", image: cape_01102695, value: "Fox Spirit Cape", weight: 8 },
      { id: 7, name: "01102157", image: cape_01102157, value: "Puppet Cape", weight: 7 },
      { id: 8, name: "01102221", image: cape_01102221, value: "Pluto Flame Cape", weight: 6 },
      { id: 9, name: "01102975", image: cape_01102975, value: "Cat Balloon Cape", weight: 5 },
      { id: 10, name: "01102808", image: cape_01102808, value: "Mouse Couple Cape", weight: 4 },
      { id: 11, name: "01102111", image: cape_01102111, value: "Elephant Balloon Cape", weight: 3 },
      { id: 12, name: "01103047", image: cape_01103047, value: "Corgi Cape", weight: 2, z: true },
      { id: 13, name: "01102945", image: cape_01102945, value: "Kitty Cape", weight: 1, z: true },
    ],
  },
};
